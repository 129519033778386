import React from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, useRowSelect } from 'react-table';

import { SortIcon, SortUpIcon, SortDownIcon } from './Icons'
import { classNames } from '@/utils'
import { Spinner } from '../Spinner'
import { debounce } from 'lodash';
import {
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';


// Global filter for UI to filter results
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <label className="flex gap-x-2 ml-4 items-baseline text-sm" style={{ alignItems: "center" }}>
      <MagnifyingGlassIcon
        className="h-5 w-5 text-gray-400"
        aria-hidden="true"
        style={{ position: "absolute", left: "25px" }}
      />
      <input
        type="text"
        className="border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        style={{ borderRadius: "10px", paddingLeft: "35px" }}
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={'Search Text...'}
      />
    </label>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline font-medium">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  )
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={
        classNames(
          "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
          status.startsWith("active") ? "bg-green-100 text-green-800" : null,
          status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
          status.startsWith("offline") ? "bg-red-100 text-red-800" : null,
        )
      }
    >
      {status}
    </span>
  );
}

function ScrollableTable(
  { columns, data, isLoading = false, selectIdentifier = null, selectedRowId = null, showFilters = false, showFilterTotals = false,
    onRowHover = null }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  )

  let debouncedRowHoverCallback;
  if (onRowHover) {
    debouncedRowHoverCallback = debounce(onRowHover, 500);
  }
  

  // Render the UI for your table
  return (
    <>
      <div className="sm:flex sm:gap-x-2">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {showFilters &&
      <div className="my-4 flex gap-x-2">
          <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          />
          {showFilterTotals &&
            <label className="flex gap-x-2 ml-4 items-baseline text-sm" style={{ alignItems: "center" }}>
              <span className="text-gray-700">Total: {rows.length} {rows.length !== 1 ? "records" : "record"}</span>
            </label>
          }
      </div>
      }
      {/* table */}
      <div className="mt-0 flex flex-col h-full">
        {/* <div className="-my-2 mx-4 sm:-mx-6 lg:-mx-8 ">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 h-full"> */}
          <div className="">
          <div className="align-middle inline-block min-w-full h-full">
            <div className="shadow h-full w-full">
              <table key={1} {...getTableProps()} className="min-w-full divide-y divide-gray-100">
                <thead className="bg-gray-50">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon className="w-4 h-4 text-gray-400" />
                                  : <SortUpIcon className="w-4 h-4 text-gray-400" />
                                : (
                                  <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {isLoading &&
                    <>
                    <tr>
                      <td colSpan={headerGroups.length}>
                        <Spinner size="md"/>
                        </td>
                    </tr>
                    </>
                  }
                  {rows.map((row, i) => {
                    prepareRow(row)
                    const {values} = row;
                    let isSelected = false;
                    if (selectIdentifier && selectedRowId) {
                      if (values[selectIdentifier] == selectedRowId) isSelected = true;
                    }
                    return (
                      <tr
                        {...row.getRowProps()}
                        //onMouseOver={() => { if (onRowHover) { debouncedRowHoverCallback(row?.values) } }} 
                        className={classNames( (isSelected ? 'bg-gray-200' : (i % 2 === 0 ? 'bg-white' : 'bg-gray-100')))}>
                        {row.cells.map((cell, j) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-sm text-gray-800">{cell.render('Cell')}</div>
                                : cell.render('Cell')
                              }
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default ScrollableTable;