/* eslint-disable import/no-unresolved */
import { v4 as uuidv4 } from 'uuid';

import { Spinner } from '@/components/Elements';
import ScrollableTable from '@/components/Elements/ScrollableTable/ScrollableTable';

import { useApiHealth } from '../api';

export const ApiHealthList = () => {
  const apiHealthQuery = useApiHealth();

  if (apiHealthQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!apiHealthQuery.data) return null;

  return (
    <ScrollableTable
      data={apiHealthQuery.data}
      columns={[
        {
          Header: 'API Name',
          accessor: 'name',
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span className="text-md font-semibold">{value}</span>;
          },
        },
        {
          Header: 'Response Code/Text',
          id: uuidv4(),
          accessor: (row: any) => `${row.responseCode} / ${row.responseText}`,
          // @ts-ignore
          Cell: ({ value }: any) => {
            const response = value.split(" / ");
            const responseCode = response[0];
            const responseText = response[1];
            return <><span className={responseCode !== "200" ? "text-red-600" : "text-green-600"}>{responseCode}</span> / <span className={responseCode !== "200" || responseText.toLowerCase().indexOf('limit') > -1 ? "text-red-600" : "text-green-600"}>{responseText}</span></>;
          },
        },
        {
          Header: 'Response Time',
          accessor: 'responseTimeMs',
          // @ts-ignore
          Cell: ( { value }: any) => {
            return <span>{(value / 1000).toFixed(2)} seconds</span>;
          },
        },
        {
          Header: 'Day/Month/YTD Count',
          id: uuidv4(),
          accessor: (row: any) => `${row.queryDayCount || 0} / ${row.queryMonthCount || 0} / ${row.queryYTD || 0}`,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value}</span>
          },
        },
        {
          Header: 'Daily Avg. YTD/All',
          id: uuidv4(),
          accessor: (row: any) => `${(row.queryDailyAverageYTD || 0).toFixed(2)} / ${(row.queryDailyAverageAllTime || 0).toFixed(2)}`,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value}</span>
          },
        },
        {
          Header: 'Monthly Avg. YTD/All',
          id: uuidv4(),
          accessor: (row: any) => `${(row.queryMonthlyAverageYTD || 0).toFixed(2)} / ${(row.queryMonthlyAverageAllTime || 0).toFixed(2)}`,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value}</span>
          },
        },
        {
          Header: 'Check Timestamp',
          accessor: 'timestamp',
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value}</span>;
          },
        },
      ]}
      showFilters={true}
      showFilterTotals={true}
    />
  );
};
