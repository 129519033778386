import * as React from 'react';

import { Button } from '@/components/Elements/Button';
import { Dialog, DialogTitle } from '@/components/Elements/Dialog';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

export type NotificationDialogProps = {
  title: string;
  body?: React.ReactElement;
  acknowledgeButtonText?: string;
  acknowledgeAction?: Function;
  icon?: 'danger' | 'info';
  isDone?: boolean;
};

export const NotificationDialog = ({
  title,
  body = <></>,
  acknowledgeButtonText = 'OK',
  acknowledgeAction = () => {},
  icon = 'danger',
  isDone = false,
}: NotificationDialogProps) => {
  const { close, open, isOpen } = useDisclosure();

  const acknowledgeButtonRef = React.useRef(null);

  React.useEffect(() => {
    open();
  }, []);

  React.useEffect(() => {
    if (isDone) {
      close();
    }
  }, [isDone, close]);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={close} initialFocus={acknowledgeButtonRef}>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="sm:flex sm:items-start">
            {icon === 'danger' && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
            )}

            {icon === 'info' && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
              </div>
            )}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                {title}
              </DialogTitle>
              {body && (
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{body}</p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-end">
            <Button
              type="button"
              variant="inverse"
              className="w-full inline-flex justify-center rounded-md border focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                acknowledgeAction();
                close();
              }}
              ref={acknowledgeButtonRef}
            >
              {acknowledgeButtonText}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
