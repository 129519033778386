type AnnouncementProps = {
  message: string;
  bgColor: string;
  textColor: string;
};

export const Announcement = ({ message, bgColor="red", textColor="white" }: AnnouncementProps) => {
  return (
    <div
        className="announcement"
        style={{
            height: "30px",
            width: "100%",
            textAlign: "center",
            color: textColor,
            fontWeight: "bolder",
        }}
    >
        <div
            style={{
                backgroundColor: bgColor,
                width: "100%",
                height: "100%",
                position: "relative",
                opacity: "0.8"
            }}
        />
        <span
            style={{
                top: "-28px",
                position: "relative",
            }}
        >
            {message}
        </span>
    </div>
  );
};
