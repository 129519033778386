import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useMountEffect } from '@react-hookz/web';
import storage from '@/utils/storage';

const icons = {
  info: <InformationCircleIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />,
  success: <CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />,
  warning: <ExclamationCircleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />,
  error: <XCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />,
};

const TRANSITION_DURATION_MS = 500;

export type NotificationProps = {
  notification: {
    id: string;
    type: keyof typeof icons;
    title: string;
    message?: string;
  };
  onDismiss: (id: string) => void;
};

export const Notification = ({
  notification: { id, type, title, message },
  onDismiss,
}: NotificationProps) => {
  const [loaded, setLoaded] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState(null);

  // If user has an invalid token, then we need to log them out.
  if (message && message.toLowerCase().indexOf("invalid token") > -1) {
    storage.clearLoginAcknowledge();
    storage.clearToken();
    if (message.toLowerCase().indexOf("cde is undergoing technical review") > -1) {
      window.location.assign("/?demo=true");
    } else {
      window.location.assign("/");
    }
  }

  useMountEffect(() => {
    setTimeout(()=> { // delay loading notification so CSS class changes and shows initial transition
      setLoaded(true);
      setCloseTimeout(setTimeout(() => { // delay auto-closing the notification
        setCloseTimeout(null);
        close();
      }, 5000));
    }, 50);
  });

  const close = () => {
    setLoaded(false); // change notification loaded state to show exit transition
    setTimeout(() => { // Allow transition to finish before actually removing notification
      onDismiss(id);
    }, TRANSITION_DURATION_MS);
    if (closeTimeout) { // if closeTimeout still hasn't executed, remove it at this point
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
  };

  return ((title || message && message.toLowerCase().indexOf("invalid token") < 0) &&
    <div
      className={`transition-opacity duration-${TRANSITION_DURATION_MS} ${loaded ? 'opacity-100' : 'opacity-0' } w-full flex flex-col items-end sm:items-ends`}
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto overflow-hidden">
        <div className="p-4" role="alert" aria-label={title}>
          <div className="flex items-start">
            <div className="flex-shrink-0">{icons[type]}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{message}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={close}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    || <div></div>
  );
};
