// these are important
export const API_URL = process?.env?.REACT_APP_API_URL as string;
export const WS_URL = process?.env?.REACT_APP_WS_URL as string;

// this is a placeholder
export const JWT_SECRET = '123456' as string;

// this describes the subpath to the application
export const PUBLIC_URL = process?.env?.PUBLIC_URL;

export const DEMO_DATA = `${process?.env?.REACT_APP_DEMO_DATA}` === "true";

