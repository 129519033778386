import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { ApiHealth } from '@/features/api-health';
import { lazyImport } from '@/utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { DataPackagesRoutes } = lazyImport(
  () => import('@/features/dataPackages'),
  'DataPackagesRoutes'
);

const { FindDevicesRoutes } = lazyImport(
  () => import('@/features/findDevices'),
  'FindDevicesRoutes'
);

// const { ExploreRoutes } = lazyImport(() => import('@/features/explore'), 'ExploreRoutes');

const { SitAwarenessRoutes } = lazyImport(
  () => import('@/features/sitAwareness'),
  'SitAwarenessRoutes'
);

//const { DevicesRoutes } = lazyImport(() => import('@/features/devices'), 'DevicesRoutes');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { AddUser } = lazyImport(() => import('@/features/users'), 'AddUser');
const { ChangePassword } = lazyImport(() => import('@/features/users'), 'ChangePassword');

const { Settings } = lazyImport(() => import('@/features/settings'), 'Settings');

const { About } = lazyImport(() => import('@/features/about'), 'About');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      //{ path: '/', element: <Dashboard /> },
      { path: '/data-packages/*', element: <DataPackagesRoutes /> },
      { path: '/discover/*', element: <FindDevicesRoutes /> },
      // { path: '/explore/*', element: <ExploreRoutes /> },
      // { path: '/devices/*', element: <DevicesRoutes /> },
      {
        path: '/sit-awareness/*',
        element: <SitAwarenessRoutes />,
        breadcrumb: 'Situational Awareness',
      },
      { path: '/api-health', element: <ApiHealth /> },
      { path: '/users', element: <Users /> },
      { path: '/users/add', element: <AddUser /> },
      { path: '/users/changePassword', element: <ChangePassword /> },
      // { path: '/profile', element: <Profile /> },
      { path: '/about', element: <About /> },
      { path: '/settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/discover" /> },
    ],
  },
];
